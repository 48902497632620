import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from "gatsby"
//Components Imports
import Layout from '../../components/layout/defaultlayout'
import { PostCard, CategoryCard} from "../../components/common/cards";
import { Carousel } from 'react-responsive-carousel'
import { MetaData } from '../../components/meta';
//Configs Imports
import { sitePostPath } from '../../utils/configs/ghost'
import { pagesConfig } from '../../utils/configs/site';
// import { configs } from '../../utils/configuration/siteconfigs';


// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const BlogPageQuery = graphql`
{
  recentPosts: allGhostPost(sort: {order: DESC, fields: published_at}, limit: 3) {
    edges {
      node {
        ...GhostPostFields
      }
    }
  }
  featuredPosts: allGhostPost(filter: {featured: {eq: true}}) {
    nodes {
      ...GhostPostFields
    }
  }

}
`
/**
* This is the blog's page index page (/blog)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*/
const Index = ({ data, location }) => {
    let recentPosts, featuredPosts
    try{
        recentPosts = data.recentPosts.edges
        featuredPosts = data.featuredPosts.nodes
        // if(photos[0]){
        //     feature_image = photos[0].src
        // }
    }
    catch{
        //TODO add offline data for recentPost or reaturePost
        recentPosts = null
        featuredPosts = null;
    }
    finally{
        // if(!featuredPosts){
        //     featuredPosts = pagesConfig.blog.featuredPosts;
        // }
    }
    const Highlight = () => {
        return (
            <div className="highlight">
                <section>
                    <Carousel dynamicHeight={true} infiniteLoop={true} autoPlay={false}>
                        {featuredPosts && featuredPosts.map((node ) => (
                            <Link to={sitePostPath + `/${node.slug}/`}>
                            <div>
                                {
                                    node.feature_image ? <img src={node.feature_image} alt={node.title + " Feature Post Image"}/> :
                                    <img src={pagesConfig.blog.featuredPosts.defaultCarouselImage} alt=" Feature Post Default Image"/>
                                }
                                <div className="legend">
                                    {node.title && <h1>{node.title} </h1>}
                                    {node.excerpt && <h4>{node.excerpt}</h4>}
                                </div>
                            </div>
                            </Link>

                        ))}
                    </Carousel>
                </section>
            </div>
        );
    }

    const Categories = () => {
        let categories = pagesConfig.blog.categories;
        return (
            <div className="categories">
                <h2 className="page-section-title"> Categories </h2>
                <section className="post-categories">
                {
                    categories && categories.map((category) => (
                        <CategoryCard title={category.key} logo={category.logo} url={category.url}/>

                    ))
                }
                </section>
            </div>
        );
    }
    
    const RecentPost = () => {
        return (
            <div className="recent-posts">
                <h2 className="page-section-title"> Recent Post </h2>
                <section className="post-feed">
                    {recentPosts.map(({ node }) => (
                        <PostCard key={node.id} post={node} />
                    ))}
                </section>
                <Link className="a-white" to="posts/page/1">
                    <div className="button">
                            View All
                    </div>
                </Link>

            </div>     
        );
    }



    return (
        <>
            <MetaData location={location}/>
            <Layout location={location} isHome={false}>
                <div id="blog-page" className="container">
                    <Highlight />
                    <Categories />
                    <hr />
                    <RecentPost />
                </div>
            </Layout>
        </>
    );
}

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}



export default Index

